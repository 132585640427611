import { createStore } from 'vuex';
import VueCookies from 'vue-cookies';
import dataStore from '@/store/modules/dataStore.js'

export default new createStore({
    state: {
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },

        setUser(state, data){
            state.user.data = data;
            VueCookies.set('hummingsurvey_accessToken', data.accessToken);
            VueCookies.set('hummingsurvey_refreshToken', data.refreshToken);
            VueCookies.set('hummingsurvey_useruid', data.useruid);
            VueCookies.set('hummingsurvey_email', data.email);
        },

        logoutUser(state){
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('hummingsurvey_accessToken');
            VueCookies.remove('hummingsurvey_refreshToken');
            VueCookies.remove('hummingsurvey_useruid');
            VueCookies.remove('hummingsurvey_email');
        },
    },
    getters: {
        user(state){
            return state.user;
        },
    },
    actions: {},
    modules: {
        dataStore: dataStore,
    },
});
