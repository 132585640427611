import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from "mitt"; // 컴포넌트간 통신

//https://primevue.org/
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import 'primeicons/primeicons.css'

import './assets/css/common.css'
import './assets/css/reset.css'   
import './assets/css/style.css'   


// https://apexcharts.com/vue-chart-demos/line-charts/basic/
import VueApexCharts from "vue3-apexcharts";


const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter

app.use(router).use(store).use(VueApexCharts).mount('#app')
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
