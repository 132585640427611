import axios from "axios";
import SERVER from '@/API/url'

const dataStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 세션 리스트 가져오기
        GetSessions: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Data/GetSessions/',
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 투표 리스트 가져오기
        GetVotes: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Data/GetVotes?email='+params.email+'&username='+params.username+'&sdate='+params.sdate+'&edate='+params.edate+'&lc_id='+params.lc_id+"&lc_name="+params.lc_name,
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default dataStore