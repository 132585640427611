<template>
    <div class="default-layout">
        <header class="header">
            <div class="logoBox">
                <a href="/"><img src="@/assets/img/logo.png" alt="로고" height="50"/></a>
            </div>
            <button class="logout-btn"  @click="signOut">로그아웃</button>
        </header>
        <div class="main-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { supabase } from '../../lib/supabaseClient'

export default {
    name: 'DefaultLayout',
    data() {
        return {
        };
    },
    methods: {
        // 로그아웃
        async signOut() {
            this.$store.commit('logoutUser');
            let { error } = await supabase.auth.signOut()
            this.$router.push("/login")
        },
    }
}
</script>

<style scoped>
.default-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    background-color: #343a40;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.logoBox a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: bold;
}

.logout-btn {
    background-color: #f8d7da;
    border: none;
    color: #721c24;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.logout-btn:hover {
    background-color: #f5c6cb;
}

.main-content {
    flex-grow: 1;
    background-color: #f8f9fa;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .logout-btn {
        margin-top: 10px;
    }
    
}
</style>
