<template>
   <div id="app">
     <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  components: {

  }
}
</script>


