export default {
    //URL: 'https://localhost:7008',
    URL: 'https://api-kd2024.onmice.net',
    ROUTES: {
        register: '/',
    },
    HEADERS: {
        AppID: '0b48a73c-5e31-45e7-83c6-b53c496a0388',
        AppKey: 'cb224bcf71e17f37b0c161fb1b92b1f3',
    },
};
